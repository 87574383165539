export default [
  {
    header: 'Apps & Pages',
  },
  {
    id: "1",
    title: 'Organizations',
    icon: 'LayersIcon',
    route: 'apps-organizations-list'
  },
  {
    id: "2",
    title: 'Tokens',
    icon: 'SlidersIcon',
    route: 'apps-tokens-list'
  },
  {
    id: "3",
    title: 'Templates',
    icon: 'GitPullRequestIcon',
    route: 'apps-template-editor-list'
  },
  {
    id: "4",
    title: 'Entities',
    icon: 'KeyIcon',
    route: 'apps-entity-list'
  },
  {
    header: 'Reports',
  },
  {
    id: "userReports",
    title: 'User Reports',
    icon: 'CpuIcon',
    route: 'apps-user-reports-list'
  },
  {
    header: 'Debug',
  },
  {
    id: "debugLogs",
    title: 'Debug logs',
    icon: 'CrosshairIcon',
    route: 'apps-debug-list'
  },
  {
    header: 'Visual Ads',
  },
  {
    id: "5",
    title: 'Template type',
    icon: 'KeyIcon',
    query: {type: "visual-ads"},
    route: 'apps-template-types-list'
  },
  {
    id: "6",
    title: 'Templates',
    icon: 'KeyIcon',
    query: {type: "visual-ads"},
    route: 'apps-visual-template-list'
  },
]
